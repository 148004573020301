import * as React from "react"
import Header from "../components/Header";
import {Helmet} from "react-helmet";
import Footer from "../components/Footer";
import {useEffect, useRef} from "react";


const QuestPage = () => {

    function resizeFrame(){
        // Selecting the iframe element

        const frame = document.getElementById("frame");
        // console.log(frame);

        frame.style.height =
            frame.contentWindow.document.body.scrollHeight + 'px';


        // set the width of the iframe as the
        // width of the iframe content
        frame.style.width  =
            frame.contentWindow.document.body.scrollWidth+'px';
    }

    useEffect(()=>{
        resizeFrame();
    },[])

    return (
        <div>
            <Helmet>
                <title>Inter Protocol - Quest</title>
            </Helmet>
            <Header/>
            <div className={'quest-page'}>
                <div className={'section content-section'}>
                    <div className={'container container-slim'}>
                        <div className={'iframe-container'}>
                            <iframe
                                id={'frame'}
                                src="https://fe-dev-v2.web3.quest/quest/d0654de4-6089-44d6-bf31-d28c27495a8a"
                                width={'100%'}
                                frameBorder={'0'}
                            />
                        </div>
                    </div>
                </div>
                <div className={'section footer-section'}>
                    <Footer/>
                </div>
            </div>

        </div>
    )
}

export default QuestPage
